/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    /* Your styles for extra small screens go here */
    .container {
        width: auto;
        margin: auto;
        padding: 2rem 4rem;
        
        .page-title {
            display: block;
            align-self: center;
            text-align: center;
            font-size: 1rem;
            margin-top: 3rem;
            margin-bottom: 0.5rem;
        }
    
        .page-body {
            font-size: 0.7rem;
            display: block;
            text-align: center;
            margin: 0 1rem;
            color: red;
        }
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    /* Your styles for small screens go here */
    .container {
        width: auto;
        margin: auto;
        padding: 2rem 4rem;

        .page-title {
            display: block;
            align-self: center;
            text-align: center;
            font-size: 1rem;
            margin-top: 3rem;
            margin-bottom: 0.5rem;
        }
    
        .page-body {
            font-size: 0.7rem;
            display: block;
            text-align: center;
            margin: 0 1rem;
            color: red;
        }
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* Your styles for medium screens go here */
    .container {
        width: auto;
        margin: auto;
        padding: 2rem 4rem;
     
        .page-title {
            display: block;
            align-self: center;
            text-align: center;
            font-size: 1.5rem;
            margin-top: 3rem;
            margin-bottom: 0.5rem;
        }
    
        .page-body {
            font-size: 0.8rem;
            display: block;
            text-align: center;
            margin: 0 5rem;
            color: red;
        }
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* Your styles for large screens go here */
    .container {
        width: auto;
        margin: auto;
        padding: 2rem 4rem;
       
    
        .page-title {
            display: block;
            align-self: center;
            text-align: center;
            font-size: 2rem;
            margin-top: 5rem;
            margin-bottom: 1rem;
        }
    
        .page-body {
            display: block;
            text-align: center;
            margin: 0 10rem;
            color: red;
        }
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* Your styles for extra large screens go here */
    .container {
        width: auto;
        margin: auto;
        padding: 2rem 4rem;
        
    
        .page-title {
            display: block;
            align-self: center;
            text-align: center;
            font-size: 2rem;
            margin-top: 5rem;
            margin-bottom: 1rem;
        }
    
        .page-body {
            display: block;
            text-align: center;
            margin: 0 20rem;
            color: red;
        }
    }
  }
  

