main {
  width: 80%;
  margin: auto;
  text-align: center;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Your styles for extra small screens go here */
  main .join-class-label {
    margin-top: 2rem;
    font-size: 1.0rem;
  }
  
  main .join-class-spinner {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  
  main .join-class-spinner .loading-spinner {
    margin-top: 1rem;
    display: inline-block;
  }

  main .join-class-title {
    font: 'Mulish';
    font-size: x-large;
    margin-top: 2rem;
  }
  
  main .join-class-button {
    font: 'Mulish';
    font-weight: 300;
    margin-top: 2rem;
    font-size: large;
    background-color: #1957b3;
    display: inline-block;
    color: white;
    padding: 0.6rem 1.5rem;
    border-radius: 0.8rem;
    cursor: pointer;
  
    
  }
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* Your styles for small screens go here */
  main .join-class-label {
    margin-top: 2rem;
    font-size: 1.0rem;
  }
  
  main .join-class-spinner {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  
  main .join-class-spinner .loading-spinner {
    margin-top: 1rem;
    display: inline-block;
  }

  main .join-class-title {
    font: 'Mulish';
    font-size: x-large;
    margin-top: 2rem;
  }
  
  main .join-class-button {
    font: 'Mulish';
    font-weight: 300;
    margin-top: 2rem;
    font-size: large;
    background-color: #1957b3;
    display: inline-block;
    color: white;
    padding: 0.6rem 1.5rem;
    border-radius: 0.8rem;
    cursor: pointer;
  
    
  }
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Your styles for medium screens go here */
  main .join-class-label {
    margin-top: 2rem;
    font-size: 1.2rem;
  }
  
  main .join-class-spinner {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  
  main .join-class-spinner .loading-spinner {
    margin-top: 1.5rem;
    display: inline-block;
  }

  main .join-class-title {
    font: 'Mulish';
    font-size: xx-large;
    margin-top: 2rem;
  }
  
  main .join-class-button {
    font: 'Mulish';
    font-weight: 300;
    margin-top: 2rem;
    font-size: large;
    background-color: #1957b3;
    display: inline-block;
    color: white;
    padding: 0.6rem 1.5rem;
    border-radius: 0.8rem;
    cursor: pointer;
  
    
  }
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Your styles for large screens go here */
  main .join-class-label {
    margin-top: 2rem;
    font-size: 1.5rem;
  }
  
  main .join-class-spinner {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  
  main .join-class-spinner .loading-spinner {
    margin-top: 3rem;
    display: inline-block;
  }

  main .join-class-title {
    font: 'Mulish';
    font-size: xx-large;
    margin-top: 2rem;
  }
  
  main .join-class-button {
    font: 'Mulish';
    font-weight: 300;
    margin-top: 2rem;
    font-size: large;
    background-color: #1957b3;
    display: inline-block;
    color: white;
    padding: 0.6rem 1.5rem;
    border-radius: 0.8rem;
    cursor: pointer;
    
  }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* Your styles for extra large screens go here */
  main .join-class-label {
    margin-top: 2rem;
    font-size: 1.5rem;
  }
  
  main .join-class-spinner {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  
  main .join-class-spinner .loading-spinner {
    margin-top: 3rem;
    display: inline-block;
  }

  main .join-class-title {
    font: 'Mulish';
    font-size: xx-large;
    margin-top: 2rem;
  }
  
  main .join-class-button {
    font: 'Mulish';
    font-weight: 300;
    margin-top: 2rem;
    font-size: large;
    background-color: #1957b3;
    display: inline-block;
    color: white;
    padding: 0.6rem 1.5rem;
    border-radius: 0.8rem;
    cursor: pointer;    
  }
  
}




