/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    /* Your styles for extra small screens go here */
    .container {
        width: auto;
        padding: 2rem 4rem;
    
        .header-title {
            font-size: 1rem;
            font-weight: 600;
            font-family: 'Mulish';
        
            .title-highlight {
                color: #1957b3;
            }
        }   
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    /* Your styles for small screens go here */
    .container {
        width: auto;
        padding: 2rem 4rem;
    
        .header-title {
            font-size: 1rem;
            font-weight: 600;
            font-family: 'Mulish';
        
            .title-highlight {
                color: #1957b3;
            }
        }   
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* Your styles for medium screens go here */
    .container {
        width: auto;
        padding: 2rem 4rem;
    
        .header-title {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: 'Mulish';
        
            .title-highlight {
                color: #1957b3;
            }
        }   
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* Your styles for large screens go here */
    .container {
        width: auto;
        padding: 2rem 4rem;
    
        .header-title {
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Mulish';
        
            .title-highlight {
                color: #1957b3;
            }
        }   
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* Your styles for extra large screens go here */
    .container {
        width: auto;
        padding: 2rem 4rem;
    
        .header-title {
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Mulish';
        
            .title-highlight {
                color: #1957b3;
            }
        }   
    }
  }
  




